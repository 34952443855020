'use client';
import React, {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react'
import useSWR from "swr";
import {HolidaysModel} from "@/app/api/ferien/route";
import useLocalStorage from "use-local-storage";

type HolidaysProps = {
    active: boolean;
}
const fetcher = (url: string) => fetch(url, {cache: "no-cache"}).then((res) => res.json());

const Holidays = (): React.JSX.Element => {
    const [holidaysSeen, setHolidaysSeen] = useLocalStorage("holidays_seen", false);
    const { data } = useSWR<HolidaysModel>('/api/ferien', fetcher);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (data != undefined) {
            if (!holidaysSeen && data.active) {
                setOpen(data.active)
            }

            if (!data.active) {
                setHolidaysSeen(false);
            }
        }
    }, [data])

    const closePopup = () => {
        setHolidaysSeen(true);
        setOpen(false);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h2"
                                                      className="text-xl font-semibold leading-4 text-gray-900 mb-5">
                                            {data?.title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-base text-gray-500">
                                                {data?.text}
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-base text-gray-500">
                                                {data?.greetings}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:ring-offset-teal-600 focus:ring-offset-2"
                                        onClick={() => closePopup()}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Holidays;